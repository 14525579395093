
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Mixins } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { mdiMagnify, mdiChevronDown, mdiCogSync, mdiCalendarSync, mdiTrashCan } from '@mdi/js';
import Page from '../views/Page.vue';
import { eventsAdminStore } from '../store';
import BestAthletesEventList from '../components/events/BestAthletesEventList.vue';
import { capitalize, eventStatusColor } from '../pipes';
import { BestAthletesEventModel } from '../models/bestAthletesEvent';
import { EventbriteEventStatus, EventbriteEventStatusValues } from '../../types/enums';
import { DebounceMixin, LocalForageMixin, PollingMixin, VuetifyMixin } from '../mixins';
import { bestAthletesEventApi } from '../api/BestAthletesEventApi';
import { DataOptions, DataPagination } from 'vuetify';
import { QueryOptions, RepositoryQuery } from '../../types/interfaces';

@Component({
	components: {
		BestAthletesEventList,
		Page,
	}
})
export default class EventAdminPage extends Mixins(DebounceMixin, LocalForageMixin, PollingMixin, VuetifyMixin){
	capitalize = capitalize;
	eventStatusColor = eventStatusColor;
	mdiCogSync = mdiCogSync;
	mdiCalendarSync = mdiCalendarSync;
	mdiMagnify = mdiMagnify;
	mdiChevronDown = mdiChevronDown;
	mdiTrashCan = mdiTrashCan;
	searchEvents: string = "";

	selectedStatuses: EventbriteEventStatus[] = [];
	page: number = 0;

	tableOptions: DataOptions = {
		page: 1,
		itemsPerPage: 8,
		sortBy: ['start'],
		sortDesc: [true],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};
	localForagePersistFields: Array<string | [string, any]> = [
		'tableOptions',
		'searchEvents',
		'selectedStatuses',
	];

	get PageLoading(): boolean{
		return eventsAdminStore.loading || this.syncLoading || this.eventsLoading || this.tableUpdatePending;
	}
	get EventListReady(): boolean{
		return eventsAdminStore.EventListReady;
	}
	get TotalEvents(): number{
		return eventsAdminStore.totalEvents;
	}
	get EventList(): BestAthletesEventModel[]{
		return eventsAdminStore.eventList;
	}

	get EventStatusSelection(): {text: string, value: string}[]{
		return EventbriteEventStatusValues.sort().map(s => ({
			text: capitalize(s),
			value: s,
		}));
	}

	mounted(): void{
		this.debounceUpdateTable();
		this.pollIntervalMs = 15000;
		this.pollCallback(() => this.debounceUpdateTable());
	}
	tableUpdatePending: boolean = false;
	debounceUpdateTable(): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable();
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(): Promise<void>{
		this.persistField(this.LocalForagePersistFieldKeys);
		return await this.loadEvents();
	}

	clearLocalForage(): void{
		this.clearPersistedFields();
	}

	eventsLoading: boolean = false;
	async loadEvents(): Promise<void>{
		this.eventsLoading = true;
		try{
			const query: RepositoryQuery<BestAthletesEventModel> = {
				search: this.searchEvents,
				fields: ['name'],
				$match:{
					status: {
						$any: this.selectedStatuses,
					},
				}
			};
			const options: QueryOptions = { 
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			await eventsAdminStore.loadEventList({ query, options });
		}catch(e){
			console.error(e);
		}finally{
			this.eventsLoading = false;
		}
	}

	syncLoading: boolean = false;
	async forceSync(): Promise<void>{
		this.syncLoading = true;
		try{
			await bestAthletesEventApi.eventbriteSync({ synchronous: true });
			this.loadEvents();
		}catch(e){
			console.error(e);
		}finally{
			this.syncLoading = false;
		}
	}
}
