
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class FormatEventGross extends Mixins(VuetifyMixin){
	@Prop({ required: true }) private gross: string;
	get IsEmpty(): boolean{
		return this.Currencies.length === 0;
	}
	get Currencies(): string[]{
		return Object.keys(this.gross);
	}
	get CurrencyFormatters(): Intl.NumberFormat[]{
		return this.Currencies.map(currency => new Intl.NumberFormat('en-CA', {
			style: 'currency',
			currency: currency
		}));
	}
	get FormattedGross(): string[]{
		return this.Currencies.map((currency,index) => this.CurrencyFormatters[index].format(this.centsToDollars(this.gross[currency])));
	}
	centsToDollars(valueInCents: number): number{
		return valueInCents / 100;
	}
}
